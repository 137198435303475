import { motion, MotionProps } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RoutePattern } from "../../../routes/RoutePattern";
import { TransitionPowerOne } from "../../FramerMotion/Transitions";
import Logo from "../../../assets/images/logo.png";
import styled from "styled-components";

const primary = "rgb(212, 0, 22)";

const NavItem = styled(Link)`
  position: relative;
  color: ${(props: any) => (props.isActive ? "white" : primary)};

  :hover {
    color: white;
    transform: scale(1.5);

    ::before {
      width: 100%;
    }
  }

  ::before {
    position: absolute;
    bottom: -10px;
    left: 0;
    content: "";
    width: ${(props: any) => (props.isActive ? "100%" : "0")};
    height: 5px;
    background: red;
    transition: all 0.3s ease;
  }
`;

const NavLink = (props: any) => {
  return <NavItem {...props}>{props.children}</NavItem>;
};

const fadeIn: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 3,
  },
};

const slideDown: MotionProps = {
  initial: {
    opacity: 0,
    y: -30,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    y: -30,
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 4.5,
  },
};

export default function Desktop() {
  const [currentRoute, setCurrentRoute] = useState("/");
  const history = useLocation();

  useEffect(() => {
    if (history.pathname) {
      setCurrentRoute(history.pathname);
    }
  }, [history]);

  function isActive(pattern: string) {
    return currentRoute === pattern ? true : false;
  }

  return (
    <ul className="nav-list hidden flex-row justify-center items-center uppercase font-display text-sm lg:flex">
      <motion.li className="mr-10" {...fadeIn}>
        <NavLink to={RoutePattern.Home} isActive={isActive(RoutePattern.Home)}>
          home
        </NavLink>
      </motion.li>
      <motion.li className="mr-10" {...fadeIn}>
        <NavLink
          to={RoutePattern.Artist}
          isActive={isActive(RoutePattern.Artist)}
        >
          artist
        </NavLink>
      </motion.li>
      <motion.li className="mr-10" {...fadeIn}>
        <NavLink
          to={RoutePattern.About}
          isActive={isActive(RoutePattern.About)}
        >
          about
        </NavLink>
      </motion.li>
      <motion.li className="mr-10" {...slideDown}>
        <Link to={RoutePattern.Home}>
          <img className="w-20" src={Logo} alt="otf logo" />
        </Link>
      </motion.li>
      <motion.li className="mr-10" {...fadeIn}>
        <NavLink
          to={RoutePattern.Music}
          isActive={isActive(RoutePattern.Music)}
        >
          music
        </NavLink>
      </motion.li>
      <motion.li className="mr-10" {...fadeIn}>
        <NavLink
          to={RoutePattern.Contact}
          isActive={isActive(RoutePattern.Contact)}
        >
          contact
        </NavLink>
      </motion.li>
      <motion.li {...fadeIn}>
        <NavLink to={RoutePattern.Shop} isActive={isActive(RoutePattern.Shop)}>
          shop
        </NavLink>
      </motion.li>
    </ul>
  );
}
