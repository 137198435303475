import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface CarouselProps {
  children: any;
}

export default function Carousel({ children }: CarouselProps) {
  const settings: Settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 1000,
    autoplaySpeed: 2000,
    focusOnSelect: true,
    pauseOnHover: false,
    dots: false,
    autoplay: false,
    arrows: false,
    swipe: true,
    swipeToSlide: true,
    className: "carousel overflow-visible",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}
