import ALBUM1 from "./images/album1.png";
import ALBUM2 from "./images/album2.png";
import ALBUM3 from "./images/album3.png";
import ALBUM4 from "./images/album4.png";
import ALBUM5 from "./images/album5.png";
import ALBUM6 from "./images/album6.png";

export interface DummyDataTypes {
  id: string;
  name: string;
  poster: string;
  mp4: string;
}

export const DummyData: DummyDataTypes[] = [
  {
    id: "1",
    name: "album",
    poster: ALBUM1,
    mp4: "https://www.w3schools.com/html/mov_bbb.mp4",
  },
  {
    id: "2",
    name: "album",
    poster: ALBUM2,
    mp4: "https://www.w3schools.com/html/mov_bbb.mp4",
  },
  {
    id: "3",
    name: "album",
    poster: ALBUM3,
    mp4: "https://www.w3schools.com/html/mov_bbb.mp4",
  },
  {
    id: "4",
    name: "album",
    poster: ALBUM4,
    mp4: "https://www.w3schools.com/html/mov_bbb.mp4",
  },
  {
    id: "5",
    name: "album",
    poster: ALBUM5,
    mp4: "https://www.w3schools.com/html/mov_bbb.mp4",
  },
  {
    id: "6",
    name: "album",
    poster: ALBUM6,
    mp4: "https://www.w3schools.com/html/mov_bbb.mp4",
  },
];
