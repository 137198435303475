import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, MotionProps } from "framer-motion";
import { useRef, useState } from "react";
import { TransitionPowerOne } from "../../components/FramerMotion/Transitions";
import { DummyDataTypes } from "./DummyData";

const slideDown: MotionProps = {
  initial: {
    y: -30,
    opacity: 0,
    zIndex: 9999,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: -30,
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

interface AlbumPlayerProps {
  data?: DummyDataTypes;
}

export default function AlbumPlayer({ data }: AlbumPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const currentlyPlaying: any = useRef(null);
  const display = isPlaying ? "none" : "flex";

  function handlePlay() {
    (currentlyPlaying.current as HTMLVideoElement).play();
    setIsPlaying(true);
  }

  return (
    <motion.div className="min-h-screen z-50" {...slideDown}>
      <video
        ref={currentlyPlaying}
        playsInline
        poster={data?.poster}
        controls={isPlaying}
        className="position-center w-full px-2 object-cover lg:w-1/2 h-3/4 z-20"
      >
        <source src={data?.mp4} type="video/mp4" />
      </video>
      <div
        className="position-center border-2 border-primary justify-center items-center w-20 h-20 rounded-full z-30"
        style={{ display }}
      >
        <button className="text-primary text-3xl" onClick={handlePlay}>
          <FontAwesomeIcon icon={faPlay} />
        </button>
      </div>
    </motion.div>
  );
}
