import { DummyDataProps } from "./DummyData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faSnapchatGhost,
  faTwitter,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import { motion, Variants } from "framer-motion";
import { SocialItem } from "../../Styled/SocialItem";
import { Button } from "../../components";
import { useState } from "react";

const variantsStagger: Variants = {
  hidden: {
    transition: {
      staggerChildren: 0,
      delayChildren: 0,
      staggerDirection: 1,
    },
  },
  visible: {
    transition: { staggerChildren: 0.8, staggerDirection: 1 },
  },
};

const variants2: Variants = {
  hidden: {
    opacity: 0,
    y: 80,
    display: "none",
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    display: "block",
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

interface ArtistCardProps {
  data: DummyDataProps[];
  buttonClick?: boolean;
}

export default function ArtistCard({ data, buttonClick }: ArtistCardProps) {
  const [currentHoveredItem, setCurrentHoveredItem] = useState<
    number | undefined
  >();
  const animate = buttonClick ? "visible" : "hidden";
  // const staggerAnimate = buttonClick ? "animate" : "initial";

  return (
    <motion.div
      initial={false}
      animate={animate}
      className={`${buttonClick ? "block" : "hidden"}`}
    >
      <motion.div
        variants={variantsStagger}
        className="artist-item grid grid-cols-1 md:grid-cols-2 py-10 z-20 | lg:grid-cols-3 lg:gap-20 "
      >
        {data.map((item, idx) => (
          <motion.div
            key={idx}
            className={`overflow-hidden item-target bg-black md:bg-transparent  | lg:mt-0 ${
              currentHoveredItem === idx ? "is-hovered" : ""
            }`}
            variants={variants2}
            onPointerOver={() => setCurrentHoveredItem(idx)}
            onPointerOut={() => setCurrentHoveredItem(undefined)}
            onTouchStart={() => setCurrentHoveredItem(idx)}
            onTouchEnd={() => setCurrentHoveredItem(undefined)}
          >
            <motion.div
              className="bg-white uppercase text-primary text-center px-3 py-2"
              // variants={variants2}
            >
              <span>{item.name}</span>
            </motion.div>
            <img
              className="w-full object-cover h-3/4 bg-black"
              src={item.img}
              alt={item.name}
              // variants={variants1}
            />
            <motion.ul className="flex flex-row justify-center items-center text-3xl text-primary bg-white px-3 py-2">
              <SocialItem className="social-item mr-5">
                <a href={item.socials[0]}>
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </SocialItem>
              <SocialItem className="social-item mr-5">
                <a href={item.socials[1]}>
                  <FontAwesomeIcon icon={faSnapchatGhost} />
                </a>
              </SocialItem>
              <SocialItem className="social-item mr-5">
                <a href={item.socials[2]}>
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </SocialItem>
              <SocialItem className="social-item ">
                <a href={item.socials[3]}>
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </SocialItem>
            </motion.ul>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
}
