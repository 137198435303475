interface ContainerProps {
  id?: string;
  className?: string;
  children: any;
}

export default function Container({ className, children }: ContainerProps) {
  return (
    <div data-testid="container" className={`px-10 lg:px-56 ${className}`}>
      {children}
    </div>
  );
}
