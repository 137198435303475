import Container from "../../components/Container/Container";
import { Footer, Scratches } from "../../components";
import { Form, Formik } from "formik";
import { motion, MotionProps } from "framer-motion";
import { TransitionPowerOne } from "../../components/FramerMotion/Transitions";

const slide: MotionProps = {
  initial: {
    y: -30,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    opacity: 0,
    y: -30,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

export default function Contact() {
  return (
    <Container id="contact" className="pt-40 relative">
      <div className="w-full lg:w-3/6 m-auto mb-10">
        <motion.p className="uppercase text-white mb-5" {...slide}>
          contact us
        </motion.p>
        <Formik
          initialValues={{ name: "", email: "", number: 0, message: "" }}
          onSubmit={async (values) => {
            try {
              alert(values);
            } catch (error) {}
          }}
        >
          {() => (
            <Form className="flex flex-col text-center">
              <motion.input
                className="mb-5 px-3 py-2 text-center uppercase rounded-none"
                name="name"
                placeholder="name"
                type="text"
                {...slide}
                transition={{ ...TransitionPowerOne, delay: 0.2 }}
              />
              <motion.input
                className="mb-5 px-3 py-2 text-center uppercase rounded-none"
                name="email"
                placeholder="email"
                type="email"
                {...slide}
                transition={{ ...TransitionPowerOne, delay: 0.4 }}
              />
              <motion.input
                className="mb-5 px-3 py-2 text-center uppercase rounded-none"
                name="number"
                placeholder="number"
                type="number"
                {...slide}
                transition={{ ...TransitionPowerOne, delay: 0.6 }}
              />
              <motion.textarea
                className="mb-5 px-3 py-2 text-center uppercase rounded-none"
                name="message"
                placeholder="message"
                rows={10}
                {...slide}
                transition={{ ...TransitionPowerOne, delay: 0.8 }}
              />
              <motion.div
                {...slide}
                transition={{ ...TransitionPowerOne, delay: 1 }}
              >
                <button
                  type="submit"
                  className="text-black font-body italic bg-primary px-14 py-2 uppercase w-full lg:w-auto"
                >
                  submit
                </button>
              </motion.div>
            </Form>
          )}
        </Formik>
      </div>
      <Scratches showSmoke />
      <Footer />
    </Container>
  );
}
