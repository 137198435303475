import { motion, MotionProps } from "framer-motion";
import { TransitionPowerOne } from "../FramerMotion/Transitions";

const fadeIn: MotionProps = {
  initial: {
    opacity: 0,
    y: 30,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

interface ButtonProps {
  className?: string;
  onClick?: (e: any) => void;
  children: any;
  type?: "button" | "submit" | "reset" | undefined;
}

export default function Button({
  children,
  type,
  className,
  onClick,
  ...rest
}: ButtonProps) {
  return (
    <motion.button
      {...rest}
      type={type}
      className={"primary-btn " + className}
      {...fadeIn}
      transition={{ ...TransitionPowerOne, delay: 2.5 }}
      onClick={onClick}
    >
      {children}
    </motion.button>
  );
}
