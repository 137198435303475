import { useEffect } from "react";
import { StartSmoke } from "./Animation";

export default function Smoke() {
  useEffect(() => {
    StartSmoke();
  }, []);

  return <canvas className="absolute" id="canvas" />;
}
