import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

interface ArtistViewMoreProps {
  setIsClick: (e: boolean) => void;
}

export default function ArtistViewMore({ setIsClick }: ArtistViewMoreProps) {
  const [state, set] = useState(false);

  function handleClick() {
    set(!state);
    setIsClick(!state);
  }

  return (
    <div className="text-primary w-full text-center mb-20">
      <button
        className="m-auto uppercase outline-none focus:outline-none"
        onClick={handleClick}
      >
        {!state ? (
          <span className="flex flex-col items-center">
            <FontAwesomeIcon className="mb-2 text-xl" icon={faArrowDown} />
            view more
          </span>
        ) : (
          <span className="flex flex-col items-center">
            <FontAwesomeIcon className="mb-2 text-xl" icon={faArrowUp} />
            view less
          </span>
        )}
      </button>
    </div>
  );
}
