import { motion, MotionProps } from "framer-motion";
import styled from "styled-components";
import { TransitionPowerOne } from "../../components/FramerMotion/Transitions";
import WAVE from "./images/bg-2.png";

const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0% !important;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 130%;
  @media (min-width: 1024px) {
    background-image: url(${WAVE});
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom;
    transform: translateZ(250px) scale(0.8);
  }
`;

const slideDown: MotionProps = {
  initial: {
    top: "0%",
    opacity: 0,
    zIndex: 1,
  },
  animate: {
    top: "50%",
    opacity: 1,
    zIndex: 1,
  },
  exit: {
    top: "60%",
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 0.4,
  },
};

// const slideUp: MotionProps = {
//   initial: {
//     top: "80%",
//     opacity: 0,
//   },
//   animate: {
//     top: "50%",
//     opacity: 1,
//   },
//   exit: {
//     top: "80%",
//     opacity: 0,
//   },
//   transition: {
//     ...TransitionPowerOne,
//     delay: 0.8,
//   },
// };

export default function Background() {
  return (
    <Wrapper className="w-full hidden lg:block" {...slideDown}>
      {/* <img src={WAVE} alt="wave" className="w-3/4 h-3/4 object-fill z-10" /> */}
    </Wrapper>
  );
}
