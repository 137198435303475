import { AnimatePresence } from "framer-motion";
import { Route, Switch, useLocation } from "react-router-dom";
import { RoutePattern } from "./RoutePattern";
import {
  About,
  Artist,
  Contact,
  Home,
  Music,
  PageNotFound,
} from "../app/index";

export default function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <Route exact path={RoutePattern.Home} component={Home} />
        <Route path={RoutePattern.Artist} component={Artist} />
        <Route path={RoutePattern.About} component={About} />
        <Route path={RoutePattern.Music} component={Music} />
        <Route path={RoutePattern.Contact} component={Contact} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </AnimatePresence>
  );
}
