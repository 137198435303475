import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";

export default function Navigation() {
  return (
    <div className="w-full py-5 absolute" style={{ zIndex: 213123 }}>
      <Desktop />
      <Mobile />
    </div>
  );
}
