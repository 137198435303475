import { motion, MotionProps } from "framer-motion";
import { useEffect, useState } from "react";
import { Button, Footer, Scratches, FooterForm } from "../../components";
import Container from "../../components/Container/Container";
import { TransitionPowerOne } from "../../components/FramerMotion/Transitions";
import CHAIN from "./images/chain.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import styled from "styled-components";

const Input = styled.input`
  @media (max-width: 768px) {
    ::placeholder {
      color: black;
    }
  }
`;

gsap.registerPlugin(ScrollTrigger);
const slideUp: MotionProps = {
  initial: {
    y: 30,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 30,
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

const slideDown: MotionProps = {
  initial: {
    y: -30,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: -30,
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 0.8,
  },
};

export default function About() {
  const [click, setClick] = useState(false);

  function handleClick() {
    setClick(!click);
  }

  useEffect(() => {
    const paragraph = document.querySelectorAll(".para");
    for (let i = 0; i <= paragraph.length; i++) {
      gsap.set(paragraph[i], {
        y: 30,
        opacity: 0.2,
      });

      gsap.to(paragraph[i], {
        scrollTrigger: {
          trigger: paragraph[i],
          start: "top bottom",
          toggleActions: "restart pause reverse reset",
          scrub: 1,
        },
        y: 0,
        opacity: 1,
      });
    }
  }, []);

  return (
    <Container id="about" className="relative">
      <div className="z-10 text-white text-center pt-24 lg:pt-10 mb-10">
        <motion.img
          src={CHAIN}
          alt="car"
          className="para w-full h-full"
          {...slideUp}
        />
        <motion.p className="para mt-10" {...slideUp}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam a id
          beatae, eius illo dolorum ullam cum velit, nisi sed asperiores, enim
          suscipit cumque alias possimus sint eligendi? Vero obcaecati quos ab
          eveniet amet quisquam sunt. Eveniet distinctio dolorum ex amet
          corrupti? Molestiae quae vel nulla omnis magnam sint dignissimos hic
          voluptas est nemo optio magni in dicta saepe praesentium,
          necessitatibus animi ullam inventore odio eius numquam sunt delectus
          deleniti illo? Quaerat magni, voluptatem officia aliquid soluta quas
          ducimus voluptatum!
        </motion.p>
        <br />
        <motion.p className="para mt-5" {...slideUp}>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. In illum
          officia quae, dolorem impedit deleniti eveniet dolores neque, quis
          ullam soluta excepturi perspiciatis. Doloremque nisi quas reiciendis
          ad dicta iure eius laudantium repellat architecto natus magnam quidem
          eligendi delectus minima, veniam quaerat soluta, quam at nam esse
          dignissimos assumenda nesciunt itaque! Voluptates vero rem, magnam
          voluptas ex explicabo nobis rerum pariatur animi distinctio obcaecati
          deleniti consequatur dicta enim recusandae. Facilis quaerat maxime
          amet. Optio, animi! Iste blanditiis sequi aliquid doloremque.
        </motion.p>
        <br />
        <motion.p className="para mt-5" {...slideUp}>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum est
          et repellat expedita eum? Ipsam ullam veniam quod maxime itaque
          quaerat laborum neque sequi debitis culpa impedit esse quos quo
          consequuntur blanditiis, nostrum saepe temporibus fugit hic alias
          quasi omnis, dolorum voluptates. Odit amet id alias adipisci deserunt
          ipsum dicta rerum vel eius commodi. Laboriosam distinctio quae, beatae
          fugit aliquid dolore ipsa error libero corrupti nemo quidem
          perferendis necessitatibus debitis exercitationem veritatis, adipisci
          optio tenetur eum alias amet, iure deserunt?
        </motion.p>
        <motion.div className="flex flex-col mt-16 text-center" {...slideDown}>
          {!click ? (
            <Button
              className="w-full lg:w-1/4 py-3 italic m-auto"
              onClick={handleClick}
            >
              Email
            </Button>
          ) : (
            <FooterForm />
          )}
        </motion.div>
      </div>
      <Scratches showSmoke />
      <Footer />
    </Container>
  );
}
