import { Variants } from "framer-motion";
import { TransitionPowerOne } from "./Transitions";

export const ListsVariants: Variants = {
  initial: {
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
  animate: {
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.1,
    },
  },
  exit: {
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
};

export const ListsSlideUpStagger: Variants = {
  initial: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 100 },
    },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ...TransitionPowerOne,
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: {
      ...TransitionPowerOne,
      y: { stiffness: 100 },
    },
  },
};
