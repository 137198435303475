import { motion, MotionProps } from "framer-motion";
import { Footer, Scratches } from "../../components";
import Container from "../../components/Container/Container";
import { TransitionPowerOne } from "../../components/FramerMotion/Transitions";

const fade: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

export default function PageNotFound() {
  return (
    <Container id="404" className="relative flex flex-col min-h-screen">
      <motion.div
        className="text-white m-auto uppercase font-display text-center"
        {...fade}
      >
        <p className="text-6xl">404</p>
        <p>page not found</p>
      </motion.div>
      <Scratches showSmoke />
      <Footer />
    </Container>
  );
}
