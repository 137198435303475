import Background from "../../assets/images/background.jpg";
import { Smoke, Footer } from "../../components";
import { motion, MotionProps } from "framer-motion";
import { TransitionPowerOne } from "../../components/FramerMotion/Transitions";

const fadeIn: MotionProps = {
  initial: {
    opacity: 0.5,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
    scale: 1.1,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

export default function Home() {
  return (
    <>
      <motion.div
        {...fadeIn}
        id="home"
        className="behind-1"
        style={{ height: "auto" }}
      >
        <div className="home-bg absolute w-full h-full overflow-hidden">
          <img
            className="w-full h-full absolute object-cover "
            src={Background}
            alt="car with chains"
          />
        </div>
        <div className="opacity-50">
          <Smoke />
        </div>
      </motion.div>
      <div className="absolute bottom-0 text-enter w-full">
        <Footer />
      </div>
    </>
  );
}
