import Container from "../../components/Container/Container";
import ArtistCard from "./ArtistCard";
import { DummyData, DummyData2 } from "./DummyData";
import { Footer, Scratches } from "../../components";
import ArtistViewMore from "./ArtistViewMore";
import { useState } from "react";
import ArtistCardShowcase from "./ArtistCardShowcase";
import ArtistCardShadow from "./ArtistCardShadow";
import { motion, MotionProps } from "framer-motion";

const fade: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export default function Artist() {
  const [isClick, setIsClick] = useState(false);

  return (
    <Container id="artist" className="relative h-full pt-20 md:pt-32 lg:pt-40 ">
      <ArtistCardShowcase data={DummyData} />

      <ArtistCard buttonClick={isClick} data={DummyData2} />

      <motion.div {...fade}>
        <ArtistCardShadow buttonClick={isClick} data={DummyData} />
        <ArtistViewMore setIsClick={setIsClick} />
      </motion.div>

      {/* <Scratches showSmoke /> */}
      <Footer />
    </Container>
  );
}
