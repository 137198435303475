import { DummyDataProps } from "./DummyData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faSnapchatGhost,
  faTwitter,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import { motion } from "framer-motion";
import {
  ListsSlideUpStagger,
  ListsVariants,
} from "../../components/FramerMotion/Lists";
import { SocialItem } from "../../Styled/SocialItem";
import { useState } from "react";
import { slideUp, scale, slideDown } from "./Framer";

interface ArtistCardProps {
  data: DummyDataProps[];
}

export default function ArtistCardShowcase({ data }: ArtistCardProps) {
  const [currentHoveredItem, setCurrentHoveredItem] = useState<
    number | undefined
  >();
  //   const animate = buttonClick ? "visible" : "hidden";

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={ListsVariants}
      className="artist-item grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0 lg:gap-20 z-20 cursor-pointer"
    >
      {data.map((item, idx) => (
        <motion.div
          key={idx}
          variants={ListsSlideUpStagger}
          className={`overflow-hidden item-target mt-10 bg-black outline-none focus:outline-none | md:bg-transparent lg:mt-0 ${
            currentHoveredItem === idx ? "is-hovered" : ""
          }`}
          onPointerOver={() => setCurrentHoveredItem(idx)}
          onPointerOut={() => setCurrentHoveredItem(undefined)}
          onTouchStart={() => setCurrentHoveredItem(idx)}
          onTouchEnd={() => setCurrentHoveredItem(idx)}
        >
          <div className="bg-white uppercase text-primary text-center relative px-3 py-2">
            <span className="block">{item.name}</span>
          </div>
          <img
            className="w-full object-cover h-3/4 bg-black"
            src={item.img}
            alt={item.name}
          />
          <ul className="flex flex-row justify-center items-center text-3xl relative text-primary bg-white px-3 py-2 overflow-hidden">
            <SocialItem className="social-item mr-5">
              <a href={item.socials[0]}>
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </SocialItem>
            <SocialItem className="social-item mr-5">
              <a href={item.socials[1]}>
                <FontAwesomeIcon icon={faSnapchatGhost} />
              </a>
            </SocialItem>
            <SocialItem className="social-item mr-5">
              <a href={item.socials[2]}>
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </SocialItem>
            <SocialItem className="social-item ">
              <a href={item.socials[3]}>
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </SocialItem>
          </ul>
        </motion.div>
      ))}
    </motion.div>
  );
}
