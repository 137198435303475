import { motion, useCycle } from "framer-motion";
import { useRef, useState } from "react";
import { MenuToggle } from "./MenuToggle";
import { useDimensions } from "./useDimensions";
import { Menu, MenuTypes } from "./Menu";
import { Link, useHistory } from "react-router-dom";
import {
  mobileMenuVariant,
  mobileMenuContainerVariant,
  mobileMenuItemVariant,
} from "./FramerMotion";
import styled from "styled-components";

const NavContainer = styled(motion.div)`
  z-index: -1;
`;

export default function FixedMenu() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  const history = useHistory();
  const [menu, toggleMenu] = useState<string>(history.location.pathname);

  function handleMenuItemClick(i: MenuTypes) {
    toggleMenu(i.url);
    toggleOpen();
  }

  return (
    <motion.nav
      className="mobile-menu block lg:hidden"
      initial={false}
      custom={height}
      animate={isOpen ? "open" : "closed"}
      ref={containerRef}
    >
      <NavContainer
        className="w-full fixed z-50 bg-white h-0 -top-0 right-0"
        variants={mobileMenuContainerVariant}
      >
        <motion.ul
          className="flex flex-col justify-center h-full items-center uppercase"
          variants={mobileMenuVariant}
        >
          {Menu.map((i: MenuTypes, idx: number) => {
            return (
              <motion.li
                className={`${i.logo ? "hidden" : "block"} ${
                  menu === i.url ? "current-menu" : ""
                }`}
                variants={mobileMenuItemVariant}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                key={idx}
              >
                <Link
                  className="mobile-menu-item  text-black font-normal opacity-70 hover:text-primary transform text-xl | sm:text-3xl md:text-4xl"
                  onClick={() => handleMenuItemClick(i)}
                  to={i.url}
                >
                  {i.name}
                </Link>
              </motion.li>
            );
          })}
        </motion.ul>
      </NavContainer>

      <div className="h-14 w-14 flex justify-center items-center">
        <MenuToggle toggle={() => toggleOpen()} />
      </div>
    </motion.nav>
  );
}
