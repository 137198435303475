import { faDeviantart } from "@fortawesome/free-brands-svg-icons";
import { motion, MotionProps } from "framer-motion";
import { useState } from "react";
import Button from "../Button/Button";
import { TransitionPowerOne } from "../FramerMotion/Transitions";

export const fadeIn: MotionProps = {
  initial: {
    opacity: 0,
    y: 30,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  transition: {
    ...TransitionPowerOne,
  },
};

export default function FooterForm() {
  const [value, setValue] = useState("");

  return (
    <div>
      <motion.input
        value={value}
        type="text"
        className="px-5 py-2 mr-5 text-xs text-center w-full outline-none focus:outline-none | lg:w-auto lg:text-left"
        onChange={(e) => setValue(e.currentTarget.value)}
        {...fadeIn}
        transition={{ ...TransitionPowerOne, delay: 1.8 }}
      />
      <Button type="submit">submit</Button>
    </div>
  );
}
