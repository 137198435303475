import { useEffect } from "react";
import { Smoke } from "..";
import BG from "./images/scratches-bg.jpg";
import RIPPLE from "./images/ripple.png";
import * as PIXI from "pixi.js-legacy";

interface ScratchesProps {
  img?: string;
  showSmoke?: boolean;
}

export default function Scratches({ img, showSmoke }: ScratchesProps) {
  useEffect(() => {
    let app: any;
    const scratches = document.querySelector("#scratches");
    if (!scratches) return;

    app = new PIXI.Application({
      width: window.innerWidth,
      height: scratches?.clientHeight,
    });

    scratches.appendChild(app.view);

    app.stage.interactive = true;

    const loader = new PIXI.Loader();
    let posX: any,
      displacementSprite: any,
      displacementFilter: any,
      bg,
      vx: any;
    const container = new PIXI.Container();
    app.stage.addChild(container);

    loader
      .add(img ? img : BG)
      .add(RIPPLE)
      .load(setup);

    function setup() {
      if (!scratches) return null;
      posX = scratches.clientWidth / 2;
      displacementSprite = new PIXI.Sprite(loader.resources[RIPPLE].texture);
      displacementFilter = new PIXI.filters.DisplacementFilter(
        displacementSprite
      );
      displacementSprite.anchor.set(0.5);
      displacementSprite.x = scratches.clientWidth / 2;
      displacementSprite.y = scratches.clientHeight / 2;
      vx = displacementSprite.x;

      app.stage.addChild(displacementSprite);
      container.filters = [displacementFilter];
      displacementFilter.scale.x = 0;
      displacementFilter.scale.y = 0;
      bg = new PIXI.Sprite(loader.resources[img ? img : BG].texture);
      bg.width = scratches.clientWidth;
      bg.height = scratches.clientHeight;
      container.addChild(bg);

      app.stage.on("mousemove", onPointerMove).on("touchMove", onPointerMove);
      gameLoop();
    }

    function onPointerMove(e: PIXI.InteractionEvent) {
      posX = e.data.global.x;
    }

    function gameLoop() {
      requestAnimationFrame(gameLoop);
      vx += (posX - displacementSprite.x) * 0.045;
      displacementSprite.x = vx;
      let disp = Math.floor(posX - displacementSprite.x);
      if (disp < 0) disp = -disp;
      const fs = map(disp, 0, 500, 0, 120);
      disp = map(disp, 0, 500, 0.1, 0.6);
      displacementSprite.scale.x = disp;
      displacementFilter.scale.x = fs;
    }

    function map(
      n: number,
      start1: number,
      stop1: number,
      start2: number,
      stop2: number
    ) {
      const newVal =
        ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
      return newVal;
    }
  }, [img]);

  return (
    <div>
      <div
        id="scratches"
        className="w-screen min-h-screen fixed top-0 left-0 behind-1"
      />
      {showSmoke && (
        <div className="opacity-50 fixed left-0 top-0 behind-1">
          <Smoke />
        </div>
      )}
    </div>
  );
}
