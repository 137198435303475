import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import { RoutePattern } from "../../../routes/RoutePattern";
import FixedMenu from "./FixedMenu/FixedMenu";

export default function Mobile() {
  return (
    <>
      <div className="flex justify-between items-center px-5 | lg:px-10 lg:hidden">
        <Link to={RoutePattern.Home}>
          <img className="w-14" src={Logo} alt="otf logo" />
        </Link>
        <FixedMenu />
      </div>
    </>
  );
}
