import { RoutePattern } from "../../../../routes/RoutePattern";

export interface MenuTypes {
  name?: string;
  url: RoutePattern;
  logo?: string;
  alt?: string;
}

export const Menu: MenuTypes[] = [
  {
    name: "home",
    url: RoutePattern.Home,
  },
  { name: "artist", url: RoutePattern.Artist },
  {
    name: "about",
    url: RoutePattern.About,
  },
  {
    name: "music",
    url: RoutePattern.Music,
  },
  {
    name: "contact",
    url: RoutePattern.Contact,
  },
  {
    name: "shop",
    url: RoutePattern.Shop,
  },
];
