import { Scratches, Carousel, Footer } from "../../components";
import AlbumPlayer from "./AlbumPlayer";
import { DummyData, DummyDataTypes } from "./DummyData";
import { useState } from "react";
import Background from "./Background";
import styled from "styled-components";
import BG from "./images/bg.png";
import BLUR from "../../assets/images/blur.png";

const BottomBlur = styled.div`
  z-index: 1231231;
  filter: blur(30px);
  width: 120%;
`;

const Blur = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.8);
  filter: blur(100px);
  opacity: 1;
`;

const Wrapper = styled.div`
  margin-top: 5%;
  @media (min-width: 1024px) {
    background-image: url(${(props: any) => (props.bg ? props.bg : BG)});
    background-size: contain;
    background-repeat: repeat;
    background-attachment: fixed;
    transform: translateZ(-550px) scale(1.1);
  }
`;

const ParallaxWrapper = (props: any) => <Wrapper {...props} />;

export default function Music() {
  const [hover, setHover] = useState<DummyDataTypes | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState<DummyDataTypes>(
    DummyData[0]
  );

  const mapData = DummyData.map((item, idx) => {
    return (
      <div
        key={idx}
        className={`music-card-item w-full h-full cursor-pointer ${
          selectedItem === item ? "active" : ""
        } ${hover === item ? "hovered" : "not-hovered"} 
        `}
        onClick={() => setSelectedItem(item)}
        onPointerOver={() => setHover(item)}
        onPointerOut={() => setHover(undefined)}
      >
        <img
          className="w-full h-full object-cover"
          src={item.poster}
          alt={item.name}
        />
      </div>
    );
  });

  return (
    <div className="pt-10 lg:pt-36 relative overflow-hidden">
      <ParallaxWrapper
        bg={selectedItem.poster}
        className="relative h-screen lg:bg-black z-0 overflow-hidden"
      >
        <AlbumPlayer data={selectedItem} />
        <Background />
        <Blur />
      </ParallaxWrapper>
      <div className="lg:h-24 lg:-mt-5 relative lg:opacity-100">
        <div className="blur bg-black h-full" />
      </div>
      <div className="carousel-container overflow-visible m-auto pb-10 | lg:-mt-16 lg:w-auto lg:pl-24 lg:pt-32">
        <Carousel>{mapData}</Carousel>
      </div>
      <Scratches showSmoke />
      <Footer />
    </div>
  );
}
