import IMG_1 from "./images/artist1.jpg";
import IMG_2 from "./images/artist2.png";
import IMG_3 from "./images/artist3.png";

export interface DummyDataProps {
  name: string;
  img: string;
  socials: string[];
}

export const DummyData: DummyDataProps[] = [
  {
    name: "lil durk",
    img: IMG_1,
    socials: ["!#", "!#", "!#", "!#"],
  },
  {
    name: "king von",
    img: IMG_2,
    socials: ["!#", "!#", "!#", "!#"],
  },
  {
    name: "booka600",
    img: IMG_3,
    socials: ["!#", "!#", "!#", "!#"],
  },
];

export const DummyData2: DummyDataProps[] = [
  {
    name: "lil durk",
    img: IMG_1,
    socials: ["!#", "!#", "!#", "!#"],
  },
  {
    name: "king von",
    img: IMG_2,
    socials: ["!#", "!#", "!#", "!#"],
  },
  {
    name: "booka600",
    img: IMG_3,
    socials: ["!#", "!#", "!#", "!#"],
  },
  {
    name: "lil durk",
    img: IMG_1,
    socials: ["!#", "!#", "!#", "!#"],
  },
  {
    name: "king von",
    img: IMG_2,
    socials: ["!#", "!#", "!#", "!#"],
  },
  {
    name: "booka600",
    img: IMG_3,
    socials: ["!#", "!#", "!#", "!#"],
  },
  {
    name: "lil durk",
    img: IMG_1,
    socials: ["!#", "!#", "!#", "!#"],
  },
  {
    name: "king von",
    img: IMG_2,
    socials: ["!#", "!#", "!#", "!#"],
  },
  {
    name: "booka600",
    img: IMG_3,
    socials: ["!#", "!#", "!#", "!#"],
  },
];
