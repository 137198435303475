import { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import Navigation from "./components/Navigation/Navigation";
import ScrollToTop from "react-router-scroll-top";

function App() {
  return (
    <Suspense fallback={null}>
      <Router>
        <ScrollToTop>
          <Navigation />
          <Switch>
            <Route path="*" component={AppRoutes} />
          </Switch>
        </ScrollToTop>
      </Router>
    </Suspense>
  );
}

export default App;
