import { DummyDataProps } from "./DummyData";
import { motion, Variants } from "framer-motion";
import styled from "styled-components";

const ArtistItem = styled(motion.div)`
  @media (max-width: 768px) {
    :not(:first-child) {
      display: none;
    }
  }
`;

const variant: Variants = {
  hidden: {
    y: -50,
    opacity: 0,
    display: "none",
  },
  visible: {
    opacity: 0.2,
    y: 0,
    display: "block",
  },
};

interface ArtistCardProps {
  data: DummyDataProps[];
  buttonClick: boolean;
}

export default function ArtistCardShadow({
  data,
  buttonClick,
}: ArtistCardProps) {
  const animate = !buttonClick ? "visible" : "hidden";

  return (
    <motion.div initial={false} animate={animate} variants={variant}>
      <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0 lg:gap-20 z-20">
        {data.map((item, idx) => (
          <ArtistItem key={idx} className="overflow-hidden item-target mt-10">
            <div className="bg-white opacity-50 uppercase text-primary text-center px-3 py-2">
              <span>{item.name}</span>
            </div>
          </ArtistItem>
        ))}
      </motion.div>
    </motion.div>
  );
}
