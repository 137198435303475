export function StartSmoke() {
  var requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;
  window.requestAnimationFrame = requestAnimationFrame;

  init();
}

function init() {
  var canvas = document.getElementById("canvas");
  if (!canvas) return;
  var ctx = canvas.getContext("2d");

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  var parts = [];
  let minSpawnTime = 100;
  let lastTime = new Date().getTime();
  let maxLifeTime = Math.min(5000, (canvas.height / (1.5 * 60)) * 1000);
  let emitterX = -100;
  let emitterY = canvas.height - 400;
  let smokeImage = new Image();

  function spawn() {
    if (new Date().getTime() > lastTime + minSpawnTime) {
      lastTime = new Date().getTime();
      parts.push(new smoke(emitterX, emitterY));
    }
  }

  function render() {
    var len = parts.length;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    while (len--) {
      if (parts[len].y < 0 || parts[len].lifeTime > maxLifeTime) {
        parts.splice(len, 1);
      } else {
        parts[len].update();

        ctx.save();
        var offsetX = -parts[len].size / 2;
        let offsetY = -parts[len].size / 2;

        ctx.translate(parts[len].x - offsetX, parts[len].y - offsetY);
        ctx.rotate((parts[len].angle / 180) * Math.PI);
        ctx.globalAlpha = parts[len].alpha;
        ctx.drawImage(
          smokeImage,
          offsetX,
          offsetY,
          parts[len].size,
          parts[len].size
        );
        ctx.restore();
      }
    }
    spawn();
    requestAnimationFrame(render);
  }

  function smoke(x, y, index) {
    this.x = x;
    this.y = y;

    this.size = 1;
    this.startSize = 20;
    this.endSize = 250;

    this.angle = Math.random() * 359;

    this.startLife = new Date().getTime();
    this.lifeTime = 0;

    this.velY = -1 - Math.random() * 0.5;
    this.velX = Math.floor(Math.random() * -6 + 3) / 10;
  }

  smoke.prototype.update = function () {
    this.lifeTime = new Date().getTime() - this.startLife;
    this.angle += 0.2;

    var lifePerc = (this.lifeTime / maxLifeTime) * 100;

    this.size =
      this.startSize + (this.endSize - this.startSize) * lifePerc * 0.1;

    this.alpha = 1 - lifePerc * 0.01;
    this.alpha = Math.max(this.alpha, 0);

    this.x += this.velX;
    this.y += this.velY;
  };

  smokeImage.src =
    "http://somethinghitme.com/projects/particle%20test/images/smoke.png";
  smokeImage.onload = function () {
    render();
  };

  window.onresize = resizeMe;
  window.onload = resizeMe;

  function resizeMe() {
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;
  }
}
