import { motion, MotionProps } from "framer-motion";
import { TransitionPowerOne } from "../FramerMotion/Transitions";
const fade: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 0.5,
  },
};

export default function Footer() {
  return (
    <div className="py-10 relative">
      <motion.p
        data-testid="text"
        className="uppercase text-center text-xs md:text-lg text-white"
        {...fade}
      >
        {new Date().getFullYear()} only the family.
      </motion.p>
    </div>
  );
}
